import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { createContext, FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';
import { useConnectNeoWallet } from 'components/NeoWalletConnect/useConnectNeoWallet';
import { initWallets } from 'components/NeoWalletConnect/walletsConfig';
import { projectId, walletConfig } from 'utils/walletConfig';
import { Index } from './pages/index';

const queryClient = new QueryClient();

export const NeoContext = createContext({} as any);

createWeb3Modal({
  wagmiConfig: walletConfig,
  featuredWalletIds: ['aba1f652e61fd536e8a7a5cd5e0319c9047c435ef8f7e907717361ff33bb3588'],
  projectId,
  enableAnalytics: true,
});

export const App: FC = () => {
  const neoContext = useConnectNeoWallet();

  useEffect(() => {
    initWallets();
  }, []);

  return (
    <NeoContext.Provider value={neoContext}>
      <WagmiProvider config={walletConfig}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route index element={<Index />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </QueryClientProvider>
      </WagmiProvider>
    </NeoContext.Provider>
  );
};
