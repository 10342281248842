import { NeoContext } from 'App';
import { Modal, Popover } from 'antd';
import { FC, useContext, useState } from 'react';
import closeIcon from 'assets/images/close.svg';
import { formatAddress } from 'utils/tools';
import { SUPPORTED_WALLETS_CONFIG } from './walletsConfig';

interface Props {
  className?: string;
}

export const NeoWalletConnect: FC<Props> = ({ className: _className }) => {
  const { connect, disconnect, address } = useContext(NeoContext);
  const [modal, contextHolder] = Modal.useModal();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const showWallets = () => {
    const walletModal = modal.info({
      icon: null,
      width: 400,
      footer: null,
      maskClosable: true,
      content: (
        <div className="w-full pb-[20px]">
          <div className="flex items-center justify-between">
            <div className="font-mont text-[24px] font-medium">Connect Wallet</div>
            <img src={closeIcon} className="cursor-pointer" onClick={() => walletModal.destroy()} />
          </div>
          {SUPPORTED_WALLETS_CONFIG.map(config => (
            <div
              key={config.name}
              className="mt-[20px] flex cursor-pointer items-center gap-[10px] rounded-[10px] border-[1px] border-white50 bg-gray7 px-[30px] py-[20px] duration-200 hover:border-green2"
              onClick={() => {
                connect(config.name);
              }}
            >
              <img src={config.icon} className="h-[24px] w-[24px]" />
              <div className="font-mont text-[17px] font-semibold">{config.name}</div>
            </div>
          ))}
        </div>
      ),
    });
  };

  if (!address) {
    return (
      <>
        {contextHolder}
        <button className={_className} onClick={() => showWallets()}>
          Connect Neo N3
        </button>
      </>
    );
  }
  return (
    <Popover
      open={popoverOpen}
      onOpenChange={open => setPopoverOpen(open)}
      trigger="click"
      placement="bottom"
      overlayInnerStyle={{ padding: 0 }}
      content={
        <div
          className="cursor-pointer rounded-[6px] border-[1px] border-gray px-[15px] py-[10px]"
          onClick={() => {
            disconnect();
            setPopoverOpen(false);
          }}
        >
          Disconnect
        </div>
      }
    >
      <button className="cursor-pointer rounded-[6px] bg-green px-[10px] py-[7px] text-[12px] font-semibold text-black">
        {formatAddress(address)}
      </button>
    </Popover>
  );
};
